import { FlagIconImage, ModalContent, TypeStyle } from '@syconium/little-miss-figgy'
import * as RegionLocale from '@syconium/magnolia/src/components/region-locale'

import { useTranslation } from '../../../app/_providers/TranslationProvider.client'
import { useRegionLocalePicker } from '../../../lib/hooks/useRegionLocalePicker'

import * as Styles from './styles'

export interface IntlWelcomeModalContentProps {
  onRequestClose: () => void
  onRequestRegionChange: () => void
}

export function IntlWelcomeModalContent(props: IntlWelcomeModalContentProps) {
  const { globalContentfulRegionData } = useRegionLocalePicker()
  const { intlWelcomeModal } = useTranslation()

  return (
    <RegionLocale.Container>
      <RegionLocale.CloseButton onClick={props.onRequestClose} />
      <ModalContent.Header>
        <TypeStyle.HeadlineExtraExtraSmall>
          {intlWelcomeModal.welcome} {globalContentfulRegionData?.name.toLocaleUpperCase()}!
        </TypeStyle.HeadlineExtraExtraSmall>
        <FlagIconImage src={globalContentfulRegionData?.flagSrc ?? undefined} />
        <Styles.Title>{intlWelcomeModal.easierToBuy}</Styles.Title>
      </ModalContent.Header>
      <ModalContent.Body>
        <RegionLocale.BodyTextSection>
          {globalContentfulRegionData?.welcomeShippingText}
        </RegionLocale.BodyTextSection>
        <RegionLocale.BodyTextSection>
          {intlWelcomeModal.shopInYourLocalCurrency}
        </RegionLocale.BodyTextSection>
        <RegionLocale.BodyTextSection>
          {globalContentfulRegionData?.welcomeTaxAndDutyText}
        </RegionLocale.BodyTextSection>
      </ModalContent.Body>
      <RegionLocale.CtaWrapper>
        <RegionLocale.CtaButton onClick={props.onRequestClose}>
          {intlWelcomeModal.continueShopping}
        </RegionLocale.CtaButton>
        <RegionLocale.LinkStyleButton onClick={props.onRequestRegionChange}>
          {intlWelcomeModal.changeCountry}
        </RegionLocale.LinkStyleButton>
      </RegionLocale.CtaWrapper>
    </RegionLocale.Container>
  )
}
