import { css } from 'styled-components'

import { NavTab } from '@syconium/magnolia/src/types/graphql'

import { useAccountMenuItems } from '../useAccountMenuItems'

import { AccountTab } from './AccountTab'

export function useAccountTab(): NavTab {
  const { accountMenuItems } = useAccountMenuItems()

  const accountTab: NavTab = {
    __typename: 'NavTab',
    ariaLabel: 'Account',
    href: null,
    id: 'account',
    render: () => <AccountTab />,
    sections: [
      {
        __typename: 'NavListSection',
        id: 'account',
        items: accountMenuItems,
        variant: 'default',
        styles: css`
          position: absolute;
          inset-inline-end: 62px;
        `,
      },
    ],
    text: '',
    textColor: null,
    wrapperElement: 'button',
    alternateModelVariant: null,
  }

  return accountTab
}
