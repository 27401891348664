import LazyHydrate from 'react-lazy-hydration'

import { MiniCartAndOverlay } from '@syconium/magnolia/src/brunswick/components/MiniCartAndOverlay'
import { IntlWelcomeModal } from '@syconium/magnolia/src/components/modals/IntlWelcomeModal'
import { singleQueryParamValue } from '@syconium/magnolia/src/lib/routing'
import { ApolloClientProfile } from '@syconium/weeping-figs'

import { CartRecoveryContainer } from '../../../containers/CartRecoveryContainer'
import { FETCH_SITE_FIXTURES } from '../../../lib/graphql/queries/FETCH_SITE_FIXTURES'
import { UnsupportedBanner } from '../../chrome/UnsupportedBanner'
import { NavFooter } from '../../nav/NavFooter'
import { NavHeader } from '../../nav/NavHeader'
import { PromoBarFromContext } from '../../nav/PromoBar'
import { SkipToMainContentLink } from '../../nav/SkipToMainContentLink'
import { SearchOverlay } from '../../pages/search/Search/SearchOverlay'
import { PageLayoutConfig } from '../types'

interface PrimaryLayoutProps {
  children?: React.ReactNode
}

function PrimaryLayout({ children }: PrimaryLayoutProps) {
  return (
    <CartRecoveryContainer.Provider>
      <SkipToMainContentLink />

      <UnsupportedBanner />

      <LazyHydrate whenIdle>
        <SearchOverlay />
      </LazyHydrate>

      <LazyHydrate whenIdle>
        <PromoBarFromContext />
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <NavHeader />
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <>{children}</>
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <NavFooter />
      </LazyHydrate>

      <LazyHydrate whenIdle>
        <IntlWelcomeModal />
      </LazyHydrate>

      <LazyHydrate whenIdle>
        <MiniCartAndOverlay />
      </LazyHydrate>
    </CartRecoveryContainer.Provider>
  )
}

export const primaryLayoutConfig: PageLayoutConfig = {
  Layout: ({ content, options: _options }) => <PrimaryLayout>{content}</PrimaryLayout>,
  preloadDataForLayout: pageContext => {
    const content: string = singleQueryParamValue(pageContext.query.content)
    const contentClient = pageContext.apolloClientFactory.for(
      ApolloClientProfile.FigsPublicSupergraph
    )

    return contentClient.query({
      query: FETCH_SITE_FIXTURES,
      variables: { handle: 'default', preview: content === 'preview' },
    })
  },
}
