import React from 'react'

import { useTranslation } from '../../../app/_providers/TranslationProvider.client'

import { Body, Header, LinkContainer, StyledLink, Text } from './styles'

export const UnsupportedBanner: React.FC = () => {
  const {
    magnolia: { unsupportedBanner: strings },
  } = useTranslation()

  return (
    <Body>
      <Header>{strings.headline}</Header>
      <Text>{strings.text}</Text>
      <LinkContainer>
        <StyledLink href={strings.links.chrome.url}>{strings.links.chrome.text}</StyledLink>
        <StyledLink href={strings.links.firefox.url}>{strings.links.firefox.text}</StyledLink>
        <StyledLink href={strings.links.opera.url}>{strings.links.opera.text}</StyledLink>
        <StyledLink href={strings.links.safari.url}>{strings.links.safari.text}</StyledLink>
      </LinkContainer>
    </Body>
  )
}
