import React from 'react'

import { NextModal } from '../../../../components/modals/NextModal'
import { SizeChartModalService } from '../../../containers/SizeChartModalService'
import {
  modalAnimationDuration,
  standardModalBodyOpenClassName,
  standardModalPortalClassName,
} from '../../../styles/constants'

import { SizeChartModalContent } from './SizeChartModalContent'
import { Body, modalStyles } from './styles'

export const SizeChartModal: React.FC = () => {
  const { closeSizeChartModal, shouldDisplaySizeChartModal, state } =
    SizeChartModalService.useContainer()

  if (state.modalState === 'Unopened') return null

  return (
    <NextModal
      bodyOpenClassName={standardModalBodyOpenClassName}
      closeTimeoutMS={modalAnimationDuration}
      isOpen={shouldDisplaySizeChartModal}
      onRequestClose={closeSizeChartModal}
      portalClassName={standardModalPortalClassName}
      style={modalStyles}
    >
      <Body>
        <SizeChartModalContent
          onClose={closeSizeChartModal}
          sizeChart={state.sizeChart}
          initialActiveTab={state.initialActiveTab}
        />
      </Body>
    </NextModal>
  )
}
