import { KeyboardEvent, useCallback, useEffect, useRef } from 'react'
import FocusLock from 'react-focus-lock'

import { SelectMark } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { useCartDialog } from '../../../app/_providers/CartProvider.client'
import { useTranslation } from '../../../app/_providers/TranslationProvider.client'

import { MiniCartDialogHeader, MiniCartDialogHeaderContent } from './MiniCartDialogHeader'
import { CloseButton, Container } from './styles'

type MiniCartDialogProps = {
  isHidden: boolean
  isRevealed: boolean
  titleId?: string
  children?: React.ReactNode
}

export const MiniCartDialog = ({
  isHidden,
  isRevealed,
  titleId = 'minicart-title',
  children,
}: MiniCartDialogProps) => {
  const { close } = useCartDialog()
  const { cart: miniCartCopy } = useTranslation()

  const containerRef = useRef<HTMLDivElement>(null)
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (isRevealed && containerRef && containerRef.current) {
      containerRef.current.focus()
    } else if (
      !isRevealed &&
      containerRef &&
      containerRef.current &&
      closeButtonRef &&
      closeButtonRef.current
    ) {
      containerRef.current.blur()
      closeButtonRef.current.blur()
    }
  }, [isRevealed])

  const onEscapeKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        close()
      }
    },
    [close]
  )

  return (
    <Container
      role='dialog'
      aria-labelledby={titleId}
      aria-hidden={isHidden}
      hidden={isHidden}
      isRevealed={isRevealed}
      ref={containerRef}
      tabIndex={0}
      onKeyDown={onEscapeKeyDown}
    >
      <FocusLock>
        <MiniCartDialogHeader>
          <CloseButton
            aria-label={miniCartCopy.close}
            onClick={close}
            ref={closeButtonRef}
            {...trackEvent({ category: 'minicart', action: 'close' })}
          >
            <SelectMark height='16px' stroke='currentColor' width='16px' />
          </CloseButton>

          <MiniCartDialogHeaderContent />
        </MiniCartDialogHeader>
        {children}
      </FocusLock>
    </Container>
  )
}
