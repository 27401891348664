import { ComponentPropsWithRef, forwardRef } from 'react'

import { Dropdown, ModalContent, SelectMark } from '@syconium/little-miss-figgy'
import { MaybeSpaLink } from '@syconium/magnolia/src/lib/adapters/next-routing-service/MaybeSpaLink'

import { useTranslation } from '../../app/_providers/TranslationProvider.client'

import * as Styles from './styles'

type ContainerProps = ComponentPropsWithRef<typeof Styles.Container>

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, ...buttonProps }, ref) => {
    return (
      <Styles.Container ref={ref} {...buttonProps}>
        {children}
      </Styles.Container>
    )
  }
)

type CloseButtonProps = ComponentPropsWithRef<typeof Styles.CloseButton>

export const CloseButton = forwardRef<HTMLDivElement | null, CloseButtonProps>(
  ({ children, ...buttonProps }, ref) => {
    return (
      <Styles.CloseButton ref={ref} {...buttonProps}>
        <SelectMark />
        {children}
      </Styles.CloseButton>
    )
  }
)

type DropdownProps = {
  label: string
} & Omit<ComponentPropsWithRef<typeof Dropdown>, 'children'>

export const DropdownWithLabel = forwardRef<HTMLDivElement, DropdownProps>(
  ({ label, ...dropdownProps }, ref) => {
    return (
      <Styles.DropdownWrap ref={ref}>
        <Styles.Label>{label}</Styles.Label>
        <Dropdown {...dropdownProps} />
      </Styles.DropdownWrap>
    )
  }
)

type FooterLinkProps = ComponentPropsWithRef<typeof Styles.FooterLink>

export const FooterLink = forwardRef<HTMLDivElement, FooterLinkProps>(
  ({ children, ...buttonProps }, ref) => {
    return (
      <Styles.FooterLink ref={ref} {...buttonProps}>
        {children}
      </Styles.FooterLink>
    )
  }
)

type CtaButtonProps = ComponentPropsWithRef<typeof Styles.CtaButton>

export const CtaButton = forwardRef<HTMLButtonElement | null, CtaButtonProps>(
  ({ children, ...buttonProps }, ref) => {
    return (
      <Styles.CtaButton ref={ref} {...buttonProps}>
        {children}
      </Styles.CtaButton>
    )
  }
)

type BodyTextSectionProps = ComponentPropsWithRef<typeof Styles.BodyTextSection>

export const BodyTextSection = forwardRef<HTMLDivElement | null, BodyTextSectionProps>(
  ({ children, ...titleProps }, ref) => {
    return (
      <Styles.BodyTextSection ref={ref} {...titleProps}>
        {children}
      </Styles.BodyTextSection>
    )
  }
)

type LinkStyleButtonProps = ComponentPropsWithRef<typeof Styles.LinkStyleButton>

export const LinkStyleButton = forwardRef<HTMLDivElement, LinkStyleButtonProps>(
  ({ children, ...titleProps }, ref) => {
    return (
      <Styles.LinkStyleButton ref={ref} {...titleProps}>
        {children}
      </Styles.LinkStyleButton>
    )
  }
)

type CtaWrapperProps = ComponentPropsWithRef<typeof Styles.CtaWrapper>

export const CtaWrapper = forwardRef<HTMLDivElement, CtaWrapperProps>(
  ({ children, ...titleProps }, ref) => {
    return (
      <Styles.CtaWrapper ref={ref} {...titleProps}>
        {children}
      </Styles.CtaWrapper>
    )
  }
)

type TitleSpanProps = ComponentPropsWithRef<typeof Styles.TitleSpan>

export const TitleSpan = forwardRef<HTMLSpanElement, TitleSpanProps>(
  ({ children, ...titleProps }, ref) => {
    return (
      <Styles.TitleSpan ref={ref} {...titleProps}>
        {children}
      </Styles.TitleSpan>
    )
  }
)

const privacyPageUrl = '/pages/non-us-privacy-policy'
const gePrivacyUrl =
  'https://s3.global-e.com/merchantscontent/Figs/docs/FIGS_Privacy_Policy--2022_06_03.pdf'

export interface IntlFooterProps {
  onRequestClose: () => void
}

export const IntlFooter = (props: IntlFooterProps) => {
  const { regionAndLocalePicker } = useTranslation()

  return (
    <ModalContent.Footer>
      <FooterLink>
        <MaybeSpaLink onClick={props.onRequestClose} href={privacyPageUrl}>
          {regionAndLocalePicker.modal.figsPrivacyLink}
        </MaybeSpaLink>
      </FooterLink>
      <FooterLink>
        <MaybeSpaLink href={gePrivacyUrl}>{regionAndLocalePicker.modal.gePrivacyLink}</MaybeSpaLink>
      </FooterLink>
    </ModalContent.Footer>
  )
}
