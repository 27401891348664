import { useCallback, useMemo, useState } from 'react'

import { useLocalization } from '@syconium/magnolia/src/app/_providers/LocalizationProvider.client'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { ContentfulLocale } from '@syconium/magnolia/src/types/graphql'
import {
  ShopRegion,
  SupportedLocaleIdentifier,
  languageNamesByLocale,
  retrieveEnabledLocales,
  supportedRegions,
} from '@syconium/weeping-figs'

type TranslatedRegion = NonNullable<
  ReturnType<typeof useFixturesContext>['regionData']
>['regions'][number]

export type RegionLocalePickerProps = {
  globalContentfulRegionData: TranslatedRegion | undefined
  enabledLocales: SupportedLocaleIdentifier[]
  enabledRegionNames: string[]
  getLanguageName: (option: string) => string
  getRegionName: (option: string) => string
  selectedContentfulRegionData: TranslatedRegion | undefined
  handleCountryChange: (countryRegionName: string) => void
  handleSubmit: () => void
  selectedLocale: SupportedLocaleIdentifier
  selectedRegion: ShopRegion
  setSelectedLocale: (locale: SupportedLocaleIdentifier) => void
  isLoading: boolean
}

export function getRegionData<R extends { id: string; flagSrc: string | null }>({
  simpleRegion,
  enhancedRegions,
}: {
  simpleRegion: ShopRegion
  enhancedRegions: R[]
}): R | undefined {
  const enhancedRegion = enhancedRegions.find(
    enhancedRegion => enhancedRegion.id === simpleRegion.id
  )

  if (enhancedRegion && !enhancedRegion.flagSrc) {
    enhancedRegion.flagSrc = simpleRegion.flagSrc
  }

  return enhancedRegion
}

export function useRegionLocalePicker(): RegionLocalePickerProps {
  const { localeData, regionData } = useFixturesContext()
  const { locale, region, regions, changeLocale } = useLocalization()
  const languageName = languageNamesByLocale[locale] // This would not be translated...
  const [selectedRegion, setSelectedRegion] = useState<ShopRegion>(region)
  const [selectedLocale, setSelectedLocale] = useState<SupportedLocaleIdentifier>(locale)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const enabledRegions = regions.filter(supportedRegion => {
    return supportedRegion.isEnabled
  })

  const localizedRegions = useMemo(
    () =>
      enabledRegions
        .map(region => ({
          ...region,
          localizedName: regionData?.regions.find(r => r.id === region.id)?.name ?? region.name,
        }))
        .sort((a, b) => a.localizedName.localeCompare(b.localizedName)),
    [enabledRegions, regionData]
  )

  const enabledRegionNames = localizedRegions.map(region => region.name)

  const enabledLocales = retrieveEnabledLocales(selectedRegion)

  const globalContentfulRegionData = useMemo(() => {
    return getRegionData({
      simpleRegion: region,
      enhancedRegions: regionData?.regions ?? [],
    })
  }, [regionData, region])

  const selectedContentfulRegionData = useMemo(() => {
    return getRegionData({
      simpleRegion: selectedRegion,
      enhancedRegions: regionData?.regions ?? [],
    })
  }, [regionData, selectedRegion])

  const getLanguageName = useCallback(
    (option: string) => {
      const contentfulLanguageName = localeData?.locales.find(
        (l: ContentfulLocale) => l.locale === option
      )?.language
      return contentfulLanguageName ?? languageName
    },
    [languageName, localeData]
  )

  const getRegionName = useCallback(
    (option: string) => localizedRegions.find(r => r.name === option)?.localizedName ?? option,
    [localizedRegions]
  )

  const handleSubmit = useCallback(() => {
    setIsLoading(true)
    changeLocale(selectedRegion ?? supportedRegions.US, selectedLocale)
  }, [changeLocale, selectedLocale, selectedRegion])

  const handleCountryChange = useCallback(
    (countryRegionName: string) => {
      const newRegion = enabledRegions.find(r => {
        return r.name === countryRegionName
      })

      if (newRegion) {
        setSelectedRegion(newRegion)
        newRegion.id === region.id
          ? setSelectedLocale(locale)
          : setSelectedLocale(newRegion.defaultLocale)
      }
    },
    [enabledRegions, locale, region.id]
  )

  return {
    globalContentfulRegionData,
    enabledLocales,
    enabledRegionNames,
    getLanguageName,
    getRegionName,
    handleCountryChange,
    handleSubmit,
    selectedContentfulRegionData,
    selectedLocale,
    selectedRegion,
    setSelectedLocale,
    isLoading,
  }
}
