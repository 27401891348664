import Link from 'next/link'
import { FC } from 'react'

import { Logo } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { useSession } from '../../../app/_providers/SessionProvider.client'
import { useTranslation } from '../../../app/_providers/TranslationProvider.client'
import { DEFAULT_NAV_ELEMENT } from '../../../constants'

import { LogoBody } from './styles'

export const NavLogo: FC = () => {
  const {
    magnolia: { navigation: strings },
  } = useTranslation()
  const {
    attributes: { stylePreference },
  } = useSession()

  const href = stylePreference ? `/pages/${stylePreference}-home` : '/'

  return (
    <LogoBody data-testid='nav-header-logo-body'>
      <Link
        id={DEFAULT_NAV_ELEMENT}
        href={href}
        title={strings.logoTitle}
        {...trackEvent({ category: 'navigation', action: 'click logo' })}
      >
        <Logo fill={'currentColor'} />
      </Link>
    </LogoBody>
  )
}
