import styled from 'styled-components'

import {
  Grid as LMFGrid,
  TypeStyle,
  fromMd,
  timingSlower,
  untilMd,
} from '@syconium/little-miss-figgy'

import { headerHeightPixels } from '../../../../constants'

export const Body = styled.main`
  min-height: 100vh;
`

export const GridWrapper = styled.div`
  margin-bottom: 40px;
  ${untilMd} {
    padding: 0px;
  }
  ${fromMd} {
    padding: 0px 40px;
  }
`

export const SearchOverlayWrapper = styled.div`
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  position: relative;
  z-index: 200;
`

export const AutoCompleteOverlay = styled.div<{
  show: boolean
}>`
  bottom: 0;
  position: fixed;
  left: 0;
  pointer-events: ${o => (o.show ? 'unset' : 'none')};
  right: 0;
  top: 0;
  z-index: ${o => o.theme.zIndex.foregroundContent};
`

export const AutoCompleteOverlayTop = styled.div<{
  promoBarHeightDesktopPixels: number
  promoBarHeightMobilePixels: number
  show: boolean
}>`
  background-color: white;
  height: ${o => o.promoBarHeightMobilePixels + headerHeightPixels}px;
  opacity: ${o => (o.show ? 0.8 : 0)};
  transition: opacity ${timingSlower} ease;

  ${fromMd} {
    height: ${o => o.promoBarHeightDesktopPixels + headerHeightPixels}px;
  }
`

export const AutoCompleteOverlayBottom = styled.div<{
  show: boolean
}>`
  background-color: black;
  height: 100%;
  opacity: ${o => (o.show ? 0.6 : 0)};
  transition: opacity ${timingSlower} ease;
`

export const ResultsCount = styled.span`
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-align: center;
`

export const HiddenH1 = styled.h1`
  font-size: 0;
  height: 0;
  line-height: 0;
  width: 0;
`

export const RelatedSearches = styled(LMFGrid)`
  color: ${o => o.theme.color.deprecated.gray.darkest};
  font-size: 12px;
  margin: 15px 0;

  ${fromMd} {
    margin: 60px 0;
    padding: 0 40px;
  }
`

export const RelatedSearchTerm = styled.span`
  &::after {
    content: ', ';
  }

  &:last-of-type {
    &::after {
      content: none;
    }
  }

  > a {
    text-decoration: underline;

    &:hover {
      color: ${o => o.theme.color.deprecated.gray.dark};
      text-decoration: none;
    }
  }
`

export const NoResultsText = styled(TypeStyle.BodyLarge)`
  margin: 42px 0;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`
