import React, { useEffect, useRef } from 'react'

import { NavSection } from '@syconium/magnolia/src/types/graphql'

import { renderNavSection } from '../renderNavSection'

import { Body } from './styles'

export const NavDropdown: React.FC<{
  onKeyDown?: React.KeyboardEventHandler<HTMLElement> | undefined
  onClickWithin?: () => void
  section: NavSection
}> = ({ section, onKeyDown, onClickWithin }) => {
  const bodyRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const bodyElement = bodyRef.current
    if (!onClickWithin || !bodyElement) return

    bodyElement.addEventListener('click', onClickWithin)
    return () => {
      bodyElement.removeEventListener('click', onClickWithin)
    }
  }, [onClickWithin])

  return (
    <Body
      ref={bodyRef}
      onKeyDown={onKeyDown}
      styles={'styles' in section ? section.styles : undefined}
    >
      {renderNavSection(section)}
    </Body>
  )
}
