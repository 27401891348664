import styled from 'styled-components'

import { LoadingSpinner } from '@syconium/little-miss-figgy'
import { navDropdownShadow } from '@syconium/little-miss-figgy/dist/utility-classes'
import * as RegionLocale from '@syconium/magnolia/src/components/region-locale'
import { useRegionLocalePicker } from '@syconium/magnolia/src/lib/hooks/useRegionLocalePicker'
import { SupportedLocaleIdentifier } from '@syconium/weeping-figs'

import { useTranslation } from '../../../app/_providers/TranslationProvider.client'

// Positions the dropdown correctly to the left and inline with the text
export const Wrapper = styled.div`
  inset-inline-end: 14px;
  position: absolute;
  background-color: ${o => o.theme.color.deprecated.white.primary};
  ${navDropdownShadow}
`

export function NavRegionLocaleSection() {
  const {
    regionAndLocalePicker: { modal },
    magnolia: {
      general: { loading: loadingText },
    },
  } = useTranslation()
  const {
    getLanguageName,
    getRegionName,
    handleCountryChange,
    enabledLocales,
    enabledRegionNames,
    setSelectedLocale,
    handleSubmit,
    selectedLocale,
    selectedRegion,
    isLoading,
  } = useRegionLocalePicker()

  return (
    <Wrapper>
      <RegionLocale.Container type='dropdown'>
        <RegionLocale.DropdownWithLabel
          label={modal.labelCountry}
          isScrollable={true}
          onChange={handleCountryChange}
          options={enabledRegionNames}
          placeholderText={modal.placeholderCountry}
          renderOptionText={getRegionName}
          renderSelectionText={getRegionName}
          selection={selectedRegion.name}
        />
        <RegionLocale.DropdownWithLabel
          label={modal.labelLanguage}
          isScrollable={true}
          onChange={(language: SupportedLocaleIdentifier) => setSelectedLocale(language)}
          options={enabledLocales}
          placeholderText={modal.placeholderLanguage}
          renderOptionText={getLanguageName}
          renderSelectionText={getLanguageName}
          selection={selectedLocale}
        />
        <RegionLocale.CtaWrapper>
          <RegionLocale.CtaButton onClick={() => !isLoading && handleSubmit()}>
            {isLoading ? <LoadingSpinner label={loadingText} /> : modal.ctaText}
          </RegionLocale.CtaButton>
        </RegionLocale.CtaWrapper>
      </RegionLocale.Container>
    </Wrapper>
  )
}
