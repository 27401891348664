import React, { Dispatch, RefObject, SetStateAction, useEffect, useState } from 'react'

import { SizeChartFragmentFragment } from '../../../../../__generated__/graphql/catalog/graphql'

import { Label, Nav, Radio, Span, WrapperButton } from './styles'

export const SizeChartModalNav: React.FC<{
  setSelectedTableIndex: Dispatch<SetStateAction<number>>
  tables: SizeChartFragmentFragment['tables']
  initialActiveTab?: number
}> = ({ tables, setSelectedTableIndex, initialActiveTab = 0 }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(initialActiveTab)
  const [focusedTabIndex, setFocusedTabIndex] = useState(initialActiveTab)
  const radioName = 'tableNav'

  const onChange = (index: number) => {
    setSelectedTabIndex(index)
    setSelectedTableIndex(index)
  }

  const tabElementRefs: RefObject<HTMLButtonElement>[] = tables
    ? tables.map(() => React.createRef())
    : []

  const currentElement = tabElementRefs[focusedTabIndex]
  const tabsLength = tabElementRefs.length
  useEffect(() => {
    currentElement?.current?.focus()
  }, [selectedTabIndex, currentElement])

  const tabFocusHandler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'ArrowLeft') {
      setFocusedTabIndex(prev => {
        if (prev > 0) {
          return prev - 1
        } else return prev
      })
    } else if (e.key === 'ArrowRight') {
      setFocusedTabIndex(prev => {
        if (prev < tabsLength) {
          return prev + 1
        } else {
          return prev
        }
      })
    } else if (e.key === 'Enter') {
      setSelectedTabIndex(focusedTabIndex)
      setSelectedTableIndex(focusedTabIndex)
    }
  }

  return (
    <Nav role='tablist' aria-multiselectable='true'>
      {tables?.map((table, i) => {
        const tabIsActive = selectedTabIndex === i

        return (
          <WrapperButton
            key={`${i}-${table.tabLabel}`}
            role='tab'
            aria-selected={focusedTabIndex === i}
            aria-controls='sizeChartTabPanel'
            id='sizeChartTab'
            tabIndex={focusedTabIndex === i ? 0 : -1}
            ref={tabElementRefs[i]}
            onKeyDown={tabFocusHandler}
          >
            <Label>
              <Radio
                onChange={() => {
                  onChange(i)
                }}
                checked={tabIsActive}
                name={radioName}
                value={table.tabLabel || i}
              />
              <Span>{table.tabLabel}</Span>
            </Label>
          </WrapperButton>
        )
      })}
    </Nav>
  )
}
