import React, { useCallback } from 'react'
import { PolymorphicComponent } from 'styled-components'

import { ViewportSizes, useIsViewport } from '@syconium/little-miss-figgy'
import { NavContainer } from '@syconium/magnolia/src/brunswick/containers/NavContainer'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import {
  AlternateModelVariantAction,
  NavSection,
  NavTab,
} from '@syconium/magnolia/src/types/graphql'

import { usePersonalization } from '../../../app/_providers/PersonalizationProvider.client'
import { NavDropdown } from '../NavDropdown'
import { AccountNavTabModal } from '../NavTabModal'
import { NavTray } from '../NavTray'

import {
  Body,
  Tab,
  TabButton,
  TabContentWrapper,
  TabDiv,
  TabItemProps,
  TabLink,
  TabSubMenuButton,
} from './styles'

const category = 'navigation'

type TemplateProps = {
  activeTab: NavTab | null
  deselectCurrentTab: () => void
  freezeTabId?: string
  getOnClickTab: (tab: NavTab) => (event: React.MouseEvent<HTMLElement>) => void
  getOnKeyDownTab: (tab: NavTab) => (event: React.KeyboardEvent<HTMLElement>) => void
  getOnMouseEnterTab: (
    tab: NavTab
  ) => (event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>) => void
  getOnMouseLeaveTab: () => (
    event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>
  ) => void
  onKeyDownBody: (event: React.KeyboardEvent<HTMLElement>) => void
  onMouseEnterBody: (event: React.MouseEvent<HTMLElement>) => void
  onMouseLeaveBody: (event: React.MouseEvent<HTMLElement>) => void
  onSubMenuKeyDown: React.KeyboardEventHandler<HTMLElement>
  onSubMenuClickWithin: (tab: NavTab) => void
  tabs: NavTab[]
  wrapItems?: boolean
}

export const Template: React.FC<TemplateProps> = ({
  activeTab,
  deselectCurrentTab,
  freezeTabId,
  getOnClickTab,
  getOnKeyDownTab,
  getOnMouseEnterTab,
  getOnMouseLeaveTab,
  onKeyDownBody,
  onMouseEnterBody,
  onMouseLeaveBody,
  onSubMenuKeyDown,
  onSubMenuClickWithin: onSubMenuClickWithinProp,
  tabs,
  wrapItems = false,
}) => {
  const { validatePersonalizationTargetCriteria } = usePersonalization()
  const { promoBarHeightDesktop, promoBarHeightMobile } = NavContainer.useContainer()
  const isMediumOrLargeViewport = useIsViewport([ViewportSizes.medium, ViewportSizes.large])

  const onClickBody = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      // Close tray or dropdown on link click
      if ((event.target as HTMLElement).closest('A')) {
        onMouseLeaveBody({} as React.MouseEvent<HTMLElement>)
      }
    },
    [onMouseLeaveBody]
  )

  const getDefaultTabProps = useCallback(
    (tab: NavTab) => ({
      'aria-label': tab.ariaLabel,
      'isFadedOut': !!freezeTabId && freezeTabId !== tab.id,
      'onMouseEnter': getOnMouseEnterTab(tab),
      'onMouseLeave': getOnMouseLeaveTab(),
      'tabIndex': !tab.href ? 0 : undefined,
      'textColor': tab.textColor,
      ...trackEvent({ category, action: 'click tab', label: tab.text || tab.ariaLabel }),
    }),
    [freezeTabId, getOnMouseEnterTab, getOnMouseLeaveTab]
  )

  const getTabWrapperElement = useCallback(
    (tab: NavTab): PolymorphicComponent<'web', TabItemProps> => {
      if (tab.sections?.length > 0) {
        return TabSubMenuButton
      }

      if (tab.wrapperElement === 'button') return TabButton
      if (tab.wrapperElement === 'div') return TabDiv
      return TabLink
    },
    []
  )

  const getTabProps = useCallback(
    (tab: NavTab) => {
      const hasSubMenu = !!tab.sections.length
      const isActive = tab.id === activeTab?.id
      const expandableTabProps = {
        ...getDefaultTabProps(tab),
        'aria-expanded': hasSubMenu ? isActive : undefined,
        'aria-haspopup': hasSubMenu || undefined,
        'onClick': getOnClickTab(tab),
        'onFocus': () => {},
        'onKeyDown': getOnKeyDownTab(tab),
      }

      if (tab.wrapperElement === 'button') {
        return { ...expandableTabProps }
      } else if (tab.wrapperElement === 'div') {
        return { ...expandableTabProps, role: 'tab' }
      }

      return {
        ...expandableTabProps,
        href: tab.href ?? '#',
      }
    },
    [activeTab?.id, getDefaultTabProps, getOnClickTab, getOnKeyDownTab]
  )

  return (
    <Body
      promoBarHeightDesktop={promoBarHeightDesktop}
      promoBarHeightMobile={promoBarHeightMobile}
      wrapItems={wrapItems}
      onClick={onClickBody}
      onKeyDown={onKeyDownBody}
      onMouseEnter={onMouseEnterBody}
      onMouseLeave={onMouseLeaveBody}
    >
      {tabs.map(tab => {
        if (tab.alternateModelVariant && tab.alternateModelVariant.targetCriteria) {
          if (validatePersonalizationTargetCriteria(tab.alternateModelVariant.targetCriteria)) {
            if (tab.alternateModelVariant.action === AlternateModelVariantAction.hideOriginal) {
              return null
            } else if (
              tab.alternateModelVariant.validContentfulModel &&
              tab.alternateModelVariant.validContentfulModel.__typename === 'NavTab'
            ) {
              tab = tab.alternateModelVariant.validContentfulModel as NavTab
            }
          }
        }
        const isActive = tab.id === activeTab?.id
        const isSearchTab = tab.id === 'search'
        const isCartTab = tab.id === 'cart'
        let hideUnderline = false
        if (isMediumOrLargeViewport) {
          if (isSearchTab) {
            hideUnderline = true
          }
        } else if (isCartTab) {
          hideUnderline = true
        }

        const tabNodeId = `nav-tab-${tab.id}`
        const TabItem = getTabWrapperElement(tab)

        const onSubMenuClickWithin = () => {
          onSubMenuClickWithinProp(tab)
        }

        return (
          <Tab id={tabNodeId} key={tab.id}>
            <TabItem isActive={isActive} hideUnderline={hideUnderline} {...getTabProps(tab)}>
              <>{tab.render ? tab.render() : <b>{tab.text}</b>}</>
            </TabItem>

            <TabContentWrapper id={`${tabNodeId}-content`} isActive={isActive}>
              {renderTabContent(
                tab.sections,
                isActive,
                () => {
                  deselectCurrentTab()
                },
                isMediumOrLargeViewport,
                onSubMenuKeyDown,
                onSubMenuClickWithin
              )}
            </TabContentWrapper>
          </Tab>
        )
      })}
    </Body>
  )
}

const renderTabContent = (
  sections: NavSection[],
  isActive: boolean,
  onClose: () => void,
  isDesktop: boolean,
  onKeyDown: TemplateProps['onSubMenuKeyDown'],
  onClickWithin: () => void
) => {
  if (!sections.length) return null
  if (sections.length === 1) {
    return isDesktop ? (
      <NavDropdown onClickWithin={onClickWithin} onKeyDown={onKeyDown} section={sections[0]!} />
    ) : (
      <AccountNavTabModal onClose={onClose} section={sections[0]!} isOpen={isActive} />
    )
  }
  return <NavTray onClickWithin={onClickWithin} onKeyDown={onKeyDown} sections={sections} />
}
