import { useTranslation } from '../../../app/_providers/TranslationProvider.client'

import { SkipToMainContentText, SkipToMainContentLink as StyledLink } from './styles'

export const SkipToMainContentLink = () => {
  const {
    magnolia: { navigation: strings },
  } = useTranslation()

  const scrollToMain = (e: React.KeyboardEvent<HTMLAnchorElement>): void => {
    if (e.key === 'Enter' && typeof window !== 'undefined') {
      const main = window.document.querySelector('main')
      main?.setAttribute('tabIndex', '-1')
      main?.focus()
    }
  }

  return (
    <>
      {/* eslint-disable-next-line */}
      <StyledLink tabIndex={1} onKeyDown={scrollToMain}>
        <SkipToMainContentText>{strings.skipToMainContent}</SkipToMainContentText>
      </StyledLink>
    </>
  )
}
