import { NormalizedCacheObject } from '@apollo/client'
import { NextComponentType, NextPageContext } from 'next'
import NextApp, { AppContext, AppInitialProps } from 'next/app'
import { Router } from 'next/router'
import * as qs from 'query-string'
import { CookiesProvider, Cookies as ReactCookies } from 'react-cookie'
import { StyleSheetManager } from 'styled-components'
import Cookies from 'universal-cookie'

import { FIGSThemeProvider, MediaContextProvider, isPropValid } from '@syconium/little-miss-figgy'
import { SizeChartModal } from '@syconium/magnolia/src/brunswick/components/size-chart/SizeChartModal'
import {
  CONTENT_KEY,
  INCLUDE_UNAVAILABLE_PRODUCTS,
  PREVIEW,
  PRODUCT_DATA_KEY,
} from '@syconium/magnolia/src/brunswick/constants'
import { FixturesContextProvider } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { NavContainer } from '@syconium/magnolia/src/brunswick/containers/NavContainer'
import { RefsContainer } from '@syconium/magnolia/src/brunswick/containers/refs'
import { SizeChartModalService } from '@syconium/magnolia/src/brunswick/containers/SizeChartModalService'
import { GlobalStyles } from '@syconium/magnolia/src/brunswick/styles/global'
import { SearchOverlayContextProvider } from '@syconium/magnolia/src/containers/SearchOverlayContext'
import {
  ApolloClientProfile,
  ContextualizedClientFactory,
  IInitClient,
  ServiceFilterPairs,
  ShopLocalizationContext,
  buildContextForWebsitePageView,
  currencyKey,
  initializeClientFactory,
  isBrowser,
  localeKey,
  nameKey,
  regionKey,
  supportedRegions,
} from '@syconium/weeping-figs'

import { cookieKeys } from '../../../app/_config/Cookies.config'
import { headerKeys } from '../../../app/_config/Headers.config'
import { convertRequestToPreviewDirectives } from '../../../app/_config/PreviewDirectives.config'
import { sessionStorageKeys } from '../../../app/_config/Session.config'
import { AuthenticationProvider } from '../../../app/_providers/AuthenticationProvider.client'
import { CartProvider } from '../../../app/_providers/CartProvider.client'
import { CheckoutClientProvider } from '../../../app/_providers/CheckoutClientProvider.client'
import { ConsentPolicyProvider } from '../../../app/_providers/ConsentPolicyProvider.client'
import { ExperimentationProvider } from '../../../app/_providers/ExperimentationProvider.client'
import { GraphqlClientsProvider } from '../../../app/_providers/GraphqlClientsProvider.client'
import { LocalizationProvider } from '../../../app/_providers/LocalizationProvider.client'
import { PersonalizationProvider } from '../../../app/_providers/PersonalizationProvider.client'
import { PortalProvider } from '../../../app/_providers/PortalProvider.client'
import { PreviewDirectivesProvider } from '../../../app/_providers/PreviewDirectivesProvider.client'
import { SessionProvider } from '../../../app/_providers/SessionProvider.client'
import { TrackingProvider } from '../../../app/_providers/TrackingProvider.client'
import {
  TranslationProvider,
  type Translations,
} from '../../../app/_providers/TranslationProvider.client'
import { UserProvider } from '../../../app/_providers/UserProvider.client'
import { AccentColorContainer } from '../../../containers/AccentColorContainer'
import { EmailCaptureFormProvider } from '../../../containers/EmailCaptureFormContainer'
import { IterableContainer } from '../../../containers/iterable'
import { GET_NEW_TOKENS } from '../../../lib/graphql/mutations/accountServices/GET_NEW_TOKENS'
import { WithResponseCacheControl, conditionallySetCacheControl } from '../../../lib/response-cache'
import { MagnoliaRequest } from '../../../lib/server-middleware/MagnoliaRequest'
import { singleQueryParamValue } from '../../../lib/utils'
import { ContextualHead } from '../../chrome/contextual-head/ContextualHead'
import { Interstitial } from '../../chrome/Interstitial/Interstitial'
import { WithLayoutConfig, getLayoutConfigForPageView } from '../../layouts/layouts'

// Items needed for the MagnoliaApp component to render and provide context.
// These get serialized into the HTML for hydration clientside.
type MagnoliaAppInitialPropsExtensions = {
  cookies?: Record<string, unknown>
  apolloCaches?: { [key: string]: NormalizedCacheObject }
  translations: Translations
  shopLocalizationContext: ShopLocalizationContext
  apolloClientFactory: ContextualizedClientFactory
  requestIncludedLocalizationPathParam: boolean
}

// Items we want to make available for all pages to use in their getInitialProps functions.
interface MagnoliaPageContextExtensions {
  shopLocalizationContext: ShopLocalizationContext
  apolloClientFactory: ContextualizedClientFactory
}

// Items we want to enforce our page components to implement
type MagnoliaPageExtensions = WithLayoutConfig

// Items we want to enforce our page's getInitialProps functions to return
type MagnoliaPageInitialPropsExtensions = WithResponseCacheControl

// Put it all together into the types for implementing a MagnoliaPage component
export type MagnoliaPageInitialProps<IP = {}> = IP & MagnoliaPageInitialPropsExtensions
export type MagnoliaPageContext = NextPageContext & MagnoliaPageContextExtensions
export type MagnoliaPage<P, IP = P> = NextComponentType<
  MagnoliaPageContext,
  MagnoliaPageInitialProps<IP>,
  P
> &
  MagnoliaPageExtensions
export type MagnoliaAppInitialProps = MagnoliaAppInitialPropsExtensions & AppInitialProps

function extractApolloClientCache(apolloClientFactory: ContextualizedClientFactory) {
  const clients = apolloClientFactory.clients() ?? []
  const apolloCaches: { [key: string]: NormalizedCacheObject } = {}
  clients.forEach(([k, client]) => {
    apolloCaches[k] = client.cache.extract()
  })
  return apolloCaches
}

function getQueryStringComponentValue(
  parsedUrlQuery: qs.ParsedQuery<string>,
  componentKey: string
): string | null {
  if (!parsedUrlQuery) return null

  let componentValue: string | null
  const component = parsedUrlQuery[componentKey]
  if (Array.isArray(component)) {
    componentValue = component[0] ?? null
  } else {
    componentValue = component ?? null
  }

  return componentValue
}

function updateSessionForPageLoad(router: Router) {
  const queryParamKeys = [
    {
      queryKey: 'features',
      sessionKey: sessionStorageKeys.deprecatedFeatureFlags,
    },
  ] as const
  const parsedUrlQuery = qs.parseUrl(router.asPath).query

  queryParamKeys.forEach(queryParamKeyInfo => {
    const queryParamValue = getQueryStringComponentValue(
      parsedUrlQuery ?? {},
      queryParamKeyInfo.queryKey
    )
    if (queryParamValue) {
      globalThis.sessionStorage?.setItem(queryParamKeyInfo.sessionKey, queryParamValue)
    }
  })
}

// A little tricky to read.
//
// named browserClientFactory to indicate that in the server context this always remains
// null.  In the server runtime, the factory has been instantiated by middleware and is acquired
// from the request.
//
// On the browser, we want to maintain clients for the lifetime of the browser session, so we
// ensure and use this.
//
// Server: One apollo client factory context PER REQUEST
// Client: One apollo client factory context PER BROWSER SESSION
//
// Use the default provided context specific cache as there is only one context
// ever created on browser.
let browserClientFactory: ContextualizedClientFactory | null = null

const accountServicesOnError = (client: IInitClient) => {
  if (typeof window === 'undefined') {
    return Promise.resolve()
  }

  const cookies = new Cookies()
  const clearAuthCookies = () => {
    cookies.remove(cookieKeys.authToken.key, {
      path: '/',
    })
    cookies.remove(cookieKeys.authRefreshToken.key, {
      path: '/',
    })
  }
  const currentRefreshToken: string | undefined = cookies.get(cookieKeys.authRefreshToken.key)

  return client
    .mutate({
      mutation: GET_NEW_TOKENS,
      variables: { refreshToken: currentRefreshToken ?? '' },
    })
    .then(response => {
      if (response.errors?.length && response.errors.length > 0) {
        clearAuthCookies()
      } else if (
        response.data?.customerTokenRefresh?.token &&
        response.data?.customerTokenRefresh?.refreshToken
      ) {
        const { token: accessToken, refreshToken } = response.data.customerTokenRefresh
        cookies.set(cookieKeys.authToken.key, accessToken, cookieKeys.authToken.options)
        cookies.set(
          cookieKeys.authRefreshToken.key,
          refreshToken,
          cookieKeys.authRefreshToken.options
        )
      }
    })
    .catch(() => {
      clearAuthCookies()
    })
}

const figsApiBaseUrl = process.env.NEXT_PUBLIC_CLIENT_API_BASE_URL ?? '/'
const shopifyApiUrl = process.env.NEXT_PUBLIC_STOREFRONT_API_URL ?? '/'
const f = initializeClientFactory(figsApiBaseUrl, shopifyApiUrl, accountServicesOnError)

function runtimeSpecificApolloClientFactory(req?: MagnoliaRequest) {
  // Server side the client is placed on request already
  if (req) {
    return req.apolloClientFactory
  }

  // Client side transitions need to build it again
  return (
    browserClientFactory ??
    (browserClientFactory = f.createContext(runtimeSpecificShopLocalizationContext(req), {
      customerTier: 'web',
    }))
  )
}

function runtimeSpecificShopLocalizationContext(req?: MagnoliaRequest) {
  // Server side gets context from middleware which places it on the request
  if (req) {
    return req.shopLocalizationContext
  }

  // Client-side transitions build context from cookies
  const ctx = buildContextForWebsitePageView({
    cookies: new Cookies(),
  })

  return ctx
}

async function getLayoutsRequiredData(
  MagnoliaPage: MagnoliaPage<MagnoliaPageInitialProps>,
  magnoliaPageContext: MagnoliaPageContext
) {
  const { preloadDataForLayout } = getLayoutConfigForPageView({
    query: magnoliaPageContext.query,
    pageComponent: MagnoliaPage,
  })
  await preloadDataForLayout?.(magnoliaPageContext)

  // We don't return anything, it is just priming Apollo Cache
  return
}

async function getTranslationsFromMagnoliaFiles(
  magnoliaRequest: MagnoliaRequest | undefined,
  magnoliaPageContext: MagnoliaPageContext
): Promise<Translations> {
  // It is not recommended to call APIs here. But if we don't, we would need to include these fixtures
  // in our client-side bundles. We want to use the domain we are running on, but remove the port if we are
  // not localhost.
  const baseUrl = magnoliaRequest
    ? `http://${magnoliaRequest.headers.host}`.replace(/\.com\:[0-9]+?$/, '.com')
    : ''
  const translationsResp = await fetch(
    `${baseUrl}/api/static/i18n/${magnoliaPageContext.shopLocalizationContext.locale}`
  )

  const fixtureParsingTracingSpan = magnoliaRequest?.tracer
    ? magnoliaRequest.tracer.startSpan('fixtures.parsing', {
        childOf: magnoliaRequest.tracer.scope().active() ?? undefined,
      })
    : undefined
  const translations = (await translationsResp.json()) as Translations
  fixtureParsingTracingSpan?.finish()

  return translations
}

export class MagnoliaApp extends NextApp<MagnoliaAppInitialProps> {
  // This method is a little bit tricky
  //
  // On server: it runs for every request
  // On client: it runs for every TRANSITION and is not run on first SSR result
  //
  static async getInitialProps(appContext: AppContext): Promise<MagnoliaAppInitialProps> {
    // This is the request that was enhanced by our Express middleware (server.ts)
    const magnoliaRequest = appContext.ctx.req as MagnoliaRequest | undefined

    // If we are handling a request on the server, augment DataDog tracing
    const getInitialPropsTracingSpan = magnoliaRequest?.tracer
      ? magnoliaRequest.tracer.startSpan('app.getInitialProps', {
          childOf: magnoliaRequest.tracer.scope().active() ?? undefined,
          tags: {
            [localeKey]: magnoliaRequest.shopLocalizationContext?.locale,
            [regionKey]: magnoliaRequest.shopLocalizationContext?.region,
            [currencyKey]: magnoliaRequest.shopLocalizationContext?.currency,
            [nameKey]: magnoliaRequest.shopLocalizationContext?.name,
          },
        })
      : undefined

    // Use that request to create an enhanced context to pass to each page's getInitialProps function
    const magnoliaPageContext = MagnoliaApp.enhanceMagnoliaPageContext(
      appContext.ctx,
      magnoliaRequest
    )

    // Mark the page for enhancement with our type
    const MagnoliaPage = appContext.Component as MagnoliaPage<MagnoliaPageInitialProps>

    // Grab cookies from request if we are on the server
    const cookies = magnoliaRequest ? magnoliaRequest.universalCookies.getAll() : undefined

    const pageInitialPropsPromise = magnoliaRequest?.tracer
      ? magnoliaRequest.tracer.trace('page.getInitialProps', () =>
          NextApp.getInitialProps(appContext)
        )
      : NextApp.getInitialProps(appContext)

    const translationsPromise = magnoliaRequest?.tracer
      ? magnoliaRequest.tracer.trace('app.getTranslations', () =>
          getTranslationsFromMagnoliaFiles(magnoliaRequest, magnoliaPageContext)
        )
      : getTranslationsFromMagnoliaFiles(magnoliaRequest, magnoliaPageContext)

    const layoutDataPromise = magnoliaRequest?.tracer
      ? magnoliaRequest.tracer.trace('layout.getLayoutsRequiredData', () =>
          getLayoutsRequiredData(MagnoliaPage, magnoliaPageContext)
        )
      : getLayoutsRequiredData(MagnoliaPage, magnoliaPageContext)

    // Await all async items that were triggered.
    const translations = await translationsPromise
    const pageInitialProps = await pageInitialPropsPromise
    await layoutDataPromise

    // if we are running in the server, acquire cache policies specified per page render
    // and set appropriate cache-control headers.
    if (magnoliaRequest) conditionallySetCacheControl(appContext, pageInitialProps)

    // if we are running in the server context, extract apollo client caches and persist
    // as props for relay to GraphClientsContainer
    const apolloCaches = magnoliaRequest
      ? extractApolloClientCache(magnoliaPageContext.apolloClientFactory)
      : undefined

    // End tracing this function.
    getInitialPropsTracingSpan?.finish()

    const requestIncludedLocalizationPathParam =
      magnoliaRequest?.headers[headerKeys.requestIncludedLocalizationPathParam] === 'true'

    return {
      ...pageInitialProps,
      cookies,
      apolloCaches,
      translations,
      shopLocalizationContext: magnoliaPageContext.shopLocalizationContext,
      apolloClientFactory: magnoliaPageContext.apolloClientFactory,
      requestIncludedLocalizationPathParam,
    }
  }

  private static enhanceMagnoliaPageContext(
    pageContext: NextPageContext,
    magnoliaRequest?: MagnoliaRequest
  ): MagnoliaPageContext {
    const shopLocalizationContext = runtimeSpecificShopLocalizationContext(magnoliaRequest)
    const apolloClientFactory = runtimeSpecificApolloClientFactory(magnoliaRequest)

    let magnoliaPageContext = pageContext as MagnoliaPageContext
    magnoliaPageContext.shopLocalizationContext = shopLocalizationContext
    magnoliaPageContext.apolloClientFactory = apolloClientFactory

    return magnoliaPageContext
  }

  public render(): JSX.Element {
    const {
      apolloCaches,
      apolloClientFactory,
      pageProps,
      router,
      shopLocalizationContext,
      translations,
      cookies,
      requestIncludedLocalizationPathParam,
    } = this.props

    updateSessionForPageLoad(router)

    const safeApolloClientFactory = isBrowser
      ? runtimeSpecificApolloClientFactory()
      : apolloClientFactory // apolloClientFactory can't be serialized to share with browser, so `runtimeSpecificApolloClientFactory()` must be used in browser.

    const apolloClients = {
      [ApolloClientProfile.FigsPublicSupergraph]: safeApolloClientFactory.for(
        ApolloClientProfile.FigsPublicSupergraph
      ),
      [ApolloClientProfile.FigsAuthedSupergraph]: safeApolloClientFactory.for(
        ApolloClientProfile.FigsAuthedSupergraph
      ),
      [ApolloClientProfile.ShopifyStorefront]: safeApolloClientFactory.for(
        ApolloClientProfile.ShopifyStorefront
      ),
    } as const

    // Hydrate Apollo clients in browser on page load
    if (isBrowser && apolloCaches) {
      Object.entries(apolloClients).forEach(entry => {
        const apolloClientKey = entry[0]
        const apolloClient = entry[1]
        const apolloClientCacheKey = Object.keys(apolloCaches).find(apolloCacheKey =>
          apolloCacheKey.endsWith(`:${apolloClientKey}`)
        )
        const apolloClientCache = apolloClientCacheKey
          ? apolloCaches[apolloClientCacheKey]
          : undefined

        if (!apolloClientCache) return
        apolloClient.cache.restore(apolloClientCache)
      })
    }

    // If we received serialized cookies from the request, use them. Otherwise we must
    // be in the browser and can instead rely on them coming from the browser apis.
    const providedCookies = cookies ? new ReactCookies(cookies) : new ReactCookies()

    const Component = this.props.Component as MagnoliaPage<MagnoliaPageInitialProps>
    const { Layout } = getLayoutConfigForPageView({
      query: router.query,
      pageComponent: Component,
    })

    return (
      <StyleSheetManager shouldForwardProp={isPropValid} enableVendorPrefixes>
        <FIGSThemeProvider>
          <GlobalStyles />
          <CookiesProvider cookies={providedCookies}>
            <ConsentPolicyProvider>
              <MediaContextProvider disableDynamicMediaQueries={true}>
                <GraphqlClientsProvider
                  directory='pages'
                  defaultClient={apolloClients[ApolloClientProfile.FigsPublicSupergraph]}
                  authorizedClient={apolloClients[ApolloClientProfile.FigsAuthedSupergraph]}
                  shopifyClient={apolloClients[ApolloClientProfile.ShopifyStorefront]}
                >
                  <LocalizationProvider
                    initialState={{
                      region: supportedRegions[shopLocalizationContext.region],
                      currency: shopLocalizationContext.currency,
                      languageGroup: shopLocalizationContext.language,
                      locale: shopLocalizationContext.locale,
                      requestIncludedLocalizationPathParam,
                    }}
                  >
                    <PreviewDirectivesProvider
                      initialState={convertRequestToPreviewDirectives({
                        url: router.asPath,
                        cookies: providedCookies,
                      })}
                    >
                      <SessionProvider>
                        <FixturesContextProvider>
                          <AuthenticationProvider>
                            <TranslationProvider translations={translations}>
                              <UserProvider>
                                <PersonalizationProvider>
                                  <TrackingProvider>
                                    <ExperimentationProvider>
                                      <AccentColorContainer.Provider>
                                        <EmailCaptureFormProvider>
                                          <NavContainer.Provider>
                                            <CheckoutClientProvider>
                                              <CartProvider>
                                                <IterableContainer.Provider>
                                                  <RefsContainer.Provider>
                                                    <PortalProvider>
                                                      <SearchOverlayContextProvider>
                                                        <SizeChartModalService.Provider>
                                                          <ContextualHead />

                                                          {/** Page contents for current route. */}
                                                          <Layout
                                                            content={<Component {...pageProps} />}
                                                          />
                                                          {/** Global animation for client side page transitions. */}
                                                          <Interstitial />

                                                          {/** TODO: These modals are not global site items. */}
                                                          {/** They should be moved to pages that use them instead. */}
                                                          <SizeChartModal />
                                                        </SizeChartModalService.Provider>
                                                      </SearchOverlayContextProvider>
                                                    </PortalProvider>
                                                  </RefsContainer.Provider>
                                                </IterableContainer.Provider>
                                              </CartProvider>
                                            </CheckoutClientProvider>
                                          </NavContainer.Provider>
                                        </EmailCaptureFormProvider>
                                      </AccentColorContainer.Provider>
                                    </ExperimentationProvider>
                                  </TrackingProvider>
                                </PersonalizationProvider>
                              </UserProvider>
                            </TranslationProvider>
                          </AuthenticationProvider>
                        </FixturesContextProvider>
                      </SessionProvider>
                    </PreviewDirectivesProvider>
                  </LocalizationProvider>
                </GraphqlClientsProvider>
              </MediaContextProvider>
            </ConsentPolicyProvider>
          </CookiesProvider>
        </FIGSThemeProvider>
      </StyleSheetManager>
    )
  }

  componentDidMount() {
    this.persistServiceFilterPairs()

    Router.events.on('beforeHistoryChange', () => {
      updateSessionForPageLoad(this.props.router)
    })
  }

  /**
   * We apply a service filter request header to our GraphQL queries when
   * certain query parameters are set in Magnolia’s site URL. Persisting them
   * in memory allows us to navigate between Magnolia pages and retain the
   * desired service filter state.
   *
   * Current filters:
   * content=preview
   * product-data=include-unavailable
   * launch-tags=something
   */
  private persistServiceFilterPairs(): void {
    const { query } = this.props.router

    ServiceFilterPairs.includeCustomerTier('web')

    if (query[CONTENT_KEY] === PREVIEW) {
      ServiceFilterPairs.includePreviewContent()
    }
    const productDataParams = singleQueryParamValue(query[PRODUCT_DATA_KEY], undefined)
    if (productDataParams === INCLUDE_UNAVAILABLE_PRODUCTS) {
      ServiceFilterPairs.includeUnavailableProducts()
    } else if (productDataParams) {
      ServiceFilterPairs.includeLaunchTags(productDataParams)
    }
  }
}
