import { usePathname } from 'next/navigation'

import { getLinkProps, isSpaHref } from './utils'

export const useMaybeSpaLink = (href: string) => {
  const pathname = usePathname()
  const isLinkInIframe = pathname?.startsWith('/pages/plain/')
  const isSpaLink = isSpaHref(href)
  const spaLinkProps = getLinkProps(href)
  const spaLinkDisplayHref = spaLinkProps.as

  return {
    isSpaLink,
    isLinkInIframe,
    spaLinkHref: spaLinkDisplayHref,
  }
}
