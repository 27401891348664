import { FC, useEffect, useState } from 'react'

import { timingSlowerMs } from '@syconium/little-miss-figgy/dist/constants/timing'

import { useCartDialog } from '../../../app/_providers/CartProvider.client'
import { MiniCartContent } from '../MiniCart/MiniCartContent'
import { MiniCartDialog } from '../MiniCart/MiniCartDialog'

import { Overlay } from './styles'

export const MiniCartAndOverlay: FC = () => {
  const { isOpen, close } = useCartDialog()
  const [isHidden, setIsHidden] = useState(!isOpen)
  const [isRevealed, setIsRevealed] = useState(isOpen)

  /**
   * hidden is not equivalent to !revealed.
   * "revealed" is used to animate the minicart in and out.
   * "hidden" is for aria/accessibility.
   * When animating out, we need to wait to apply hidden/aria-hidden until
   * after the animation completes, otherwise the element will just disappear
   * immediately.
   */
  /**
   * When revealing the MiniCart, the hidden/aria-hidden attributes must be removed
   * from the container BEFORE transition animations begin. Accordingly, we wait until
   * the next iteration of the event loop:
   */
  useEffect(() => {
    if (isOpen !== isHidden) return

    if (isOpen) {
      setIsHidden(!isOpen)
      return
    } else {
      const timeout = setTimeout(() => {
        setIsHidden(!isOpen)
      }, timingSlowerMs)

      return () => {
        window.clearTimeout(timeout)
      }
    }
  }, [isHidden, isOpen])

  useEffect(() => {
    if (isOpen === isRevealed) return

    if (isOpen) {
      if (!isHidden) {
        requestAnimationFrame(() => {
          setIsRevealed(isOpen)
        })
      }
    } else {
      setIsRevealed(isOpen)
    }
  }, [isHidden, isOpen, isRevealed])

  return (
    <>
      <Overlay
        aria-hidden={isHidden}
        hidden={isHidden}
        isMiniCartRevealed={isRevealed}
        onClick={close}
      />

      <MiniCartDialog isHidden={isHidden} isRevealed={isRevealed}>
        <MiniCartContent isRevealed={isRevealed} />
      </MiniCartDialog>
    </>
  )
}
