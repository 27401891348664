import { Media } from '@syconium/little-miss-figgy'
import { User } from '@syconium/little-miss-figgy'

import { useAuthentication } from '../../../../app/_providers/AuthenticationProvider.client'
import { useTranslation } from '../../../../app/_providers/TranslationProvider.client'
import { useUser } from '../../../../app/_providers/UserProvider.client'

import { LoggedInTextWrapper, UserInitials, Wrapper } from './styles'

export const AccountTab = () => {
  const { isLoggedIn } = useAuthentication()
  const { user } = useUser()
  const {
    magnolia: {
      account: { navigation: strings },
    },
  } = useTranslation()

  if (!isLoggedIn || !user?.profile.firstName) {
    return <User />
  }

  return (
    <Wrapper>
      {user.profile.lastName ? (
        <UserInitials>
          {user.profile.firstName[0]}
          {user.profile.lastName[0]}
        </UserInitials>
      ) : (
        <User />
      )}
      <Media greaterThanOrEqual='md'>
        <LoggedInTextWrapper>
          {strings.menu.hi}
          {user.profile.firstName}
        </LoggedInTextWrapper>
      </Media>
    </Wrapper>
  )
}
