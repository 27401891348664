import { initialize } from '@iterable/web-sdk/dist/authorization'
import { updateCart } from '@iterable/web-sdk/dist/commerce'
import { track } from '@iterable/web-sdk/dist/events'
import debounce from 'lodash-es/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useBrowserStoredState } from '@syconium/little-miss-figgy'
import { CartContainer } from '@syconium/magnolia/src/brunswick/containers/cart'
import { Cart } from '@syconium/magnolia/src/types/figs'

import { useAuthentication } from '../../app/_providers/AuthenticationProvider.client'
import { useSession } from '../../app/_providers/SessionProvider.client'
import { COLLECTION_VIEW_EVENT, PDP_VIEW_EVENT, SOURCE_PLATFORM } from '../../constants/iterable'
import { extractNumericShopifyId } from '../../lib/shopify'

import { IterableItem } from './types'

export const useIterable = () => {
  const apiKey = process.env.NEXT_PUBLIC_ITERABLE_API_KEY ?? ''

  /*
    the iterable web sdk requires a refresh token in production mode (not in the documentation)
    our api keys were generated without the jwt requirement
    so a promise returning an empty is passed in order to use the sdk in production
  */
  const { account } = useAuthentication()
  const { setEmail: initializeIterableWithEmail } = initialize(apiKey, () => Promise.resolve(''))

  const [isIterableInitialized, setIsIterableInitialized] = useState(false)
  const { cart, status: cartStatus } = CartContainer.useContainer()
  const {
    attributes: { stylePreference },
  } = useSession()

  const iterableLastReportedCartItemsStorageKey = `@figs:iterableLastReportedCartItems`
  const { state: lastReportedCartItems, setState: setLastReportedCartItems } =
    useBrowserStoredState<string[]>({
      initialState: [],
      storage: globalThis.localStorage,
      storageKey: iterableLastReportedCartItemsStorageKey,
    })

  const syncIterableCart = useCallback(
    (cart: Cart, lastReportedCartItems: string[]) => {
      const cartItemKeys = Object.values(cart.items).map(
        value => value.variantId + `#${value.quantity}`
      )
      const cartItemsHaveNotChangedSinceLastIterableUpdate =
        JSON.stringify(cartItemKeys) === JSON.stringify(lastReportedCartItems)

      if (cartItemsHaveNotChangedSinceLastIterableUpdate) {
        return
      }

      const items: IterableItem[] = []

      for (const key in cart.items) {
        const cartItem = cart.items[key]!
        const convertedVariantId: string = (extractNumericShopifyId(cartItem.variantId) ?? '') + ''
        const convertedProductId: string = (extractNumericShopifyId(cartItem.productId) ?? '') + ''
        const iterableItem = {
          categories: [cartItem.productType],
          sourcePlatform: SOURCE_PLATFORM,
          dataFields: {
            variantId: convertedVariantId,
            productId: convertedProductId,
            productGender: cartItem.productGroup!.genderCategory,
            color: cartItem.color,
            genderPreference: stylePreference,
            availability: cartItem.product?.availableForSale,
            productGroupHandle: cartItem.productGroupHandle,
            size: cartItem.size,
            sourcePlatform: SOURCE_PLATFORM,
          },
          id: convertedVariantId,
          imageUrl: cartItem?.image?.url,
          name: `${cartItem.title} ${cartItem.description}`,
          price: cartItem.effectivePrice / 100,
          quantity: cartItem.quantity,
          sku: cartItem.sku,
        }
        items.push(iterableItem)
      }
      updateCart({
        items: items,
      })
      setLastReportedCartItems(cartItemKeys)
    },
    [stylePreference, setLastReportedCartItems]
  )

  const trackPdpView = useCallback(
    ({
      handle,
      defaultImage,
      path,
      productUrl,
      productName,
      selectedColor,
      selectedFit,
      selectedSize,
      availability,
      productId,
      category,
      variantId,
      productGender,
    }: {
      handle: string
      defaultImage: string
      path: string
      productUrl: string
      productName: string
      selectedColor: string
      selectedFit: string
      selectedSize: string
      availability: boolean
      productId: string
      variantId: string
      category: string | undefined
      productGender: string | undefined
    }) => {
      if (isIterableInitialized) {
        const variantIdWithoutPrefix = extractNumericShopifyId(variantId)
        const productIdWithoutPrefix = extractNumericShopifyId(productId)
        track({
          createdAt: Date.now(),
          eventName: PDP_VIEW_EVENT,
          dataFields: {
            handle,
            defaultImage,
            path,
            productName,
            selectedColor,
            selectedFit,
            selectedSize,
            productUrl,
            availability,
            productId: productIdWithoutPrefix,
            category,
            variantId: variantIdWithoutPrefix,
            id: variantIdWithoutPrefix,
            productGender,
            sourcePlatform: SOURCE_PLATFORM,
          },
        })
      }
    },
    [isIterableInitialized]
  )

  const trackCollectionView = useCallback(
    ({ handle, path }: { handle: string; path: string }) => {
      if (isIterableInitialized) {
        track({
          createdAt: Date.now(),
          eventName: COLLECTION_VIEW_EVENT,
          dataFields: {
            handle: handle,
            path: path,
            sourcePlatform: SOURCE_PLATFORM,
          },
        })
      }
    },
    [isIterableInitialized]
  )

  const debouncedSyncIterableCart = useMemo(() => {
    return debounce(syncIterableCart, 500)
  }, [syncIterableCart])

  useEffect(() => {
    const iterableCookieEmail = decodeURIComponent(
      document?.cookie?.match(RegExp('(?:^|;\\s*)' + 'iterableEndUserId' + '=([^;]*)'))?.[1] ?? ''
    )
    const email = account?.email ?? iterableCookieEmail

    if (isIterableInitialized || !email) {
      return
    }

    const initialize = async (email: string) => {
      await initializeIterableWithEmail(email)
      setIsIterableInitialized(true)
    }
    initialize(email)
  }, [account?.email, initializeIterableWithEmail, isIterableInitialized])

  useEffect(() => {
    // Don't track iterable cart until variant sync is complete
    if (cartStatus === 'pending') {
      return
    }

    if (!isIterableInitialized) {
      return
    }

    debouncedSyncIterableCart(cart, lastReportedCartItems)

    return () => {
      debouncedSyncIterableCart.cancel()
    }
  }, [cart, cartStatus, debouncedSyncIterableCart, isIterableInitialized, lastReportedCartItems])

  return {
    isIterableReady: isIterableInitialized,
    trackCollectionView,
    trackPdpView,
  }
}
