import React, { useState } from 'react'

import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { SizeChartFragmentFragment } from '../../../../../__generated__/graphql/catalog/graphql'
import { useTranslation } from '../../../../../app/_providers/TranslationProvider.client'
import { SizeChartTable } from '../../SizeChartTable'
import { SizeChartModalNav } from '../SizeChartModalNav'

import { CloseButton, Header, HeaderTextSmall, NavWrap, TableWrap } from './styles'

export const SizeChartModalTable: React.FC<{
  onClose?: () => void
  showHeader?: boolean
  sizeChart?: SizeChartFragmentFragment
  initialActiveTab?: number
}> = ({ onClose, showHeader = true, sizeChart, initialActiveTab = 0 }) => {
  const tabsLength = sizeChart?.tables?.length ?? 0
  //allow for reverse indexing with query param
  const calculatedInitialActiveTab =
    initialActiveTab >= 0 ? initialActiveTab : tabsLength + initialActiveTab
  const [selectedTableIndex, setSelectedTableIndex] = useState(calculatedInitialActiveTab)
  const selectedTable = sizeChart?.tables?.[selectedTableIndex]
  const {
    sizesSection: {
      sizeChart: { modalHeadline },
    },
  } = useTranslation()
  return (
    <>
      {showHeader && (
        <Header backgroundColor='white' hasBottomBorder={true}>
          <HeaderTextSmall asTag='h2'>{modalHeadline}</HeaderTextSmall>
          <CloseButton
            onClick={onClose}
            {...trackEvent({ category: 'product details', action: 'close size table modal' })}
          />
        </Header>
      )}
      {sizeChart && sizeChart?.tables?.length && sizeChart?.tables?.length > 1 ? (
        <NavWrap>
          <SizeChartModalNav
            {...{ setSelectedTableIndex }}
            initialActiveTab={calculatedInitialActiveTab}
            tables={sizeChart.tables}
          />
        </NavWrap>
      ) : null}
      {selectedTable && (
        <TableWrap>
          <SizeChartTable {...selectedTable} />
        </TableWrap>
      )}
    </>
  )
}
