import Head from 'next/head'

import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'

import { AttentiveScript } from '../../../app/_components/chrome/scripts/AttentiveScript'
import { DataDogRumScript } from '../../../app/_components/chrome/scripts/DataDogRumScript'
import { FriendBuyScript } from '../../../app/_components/chrome/scripts/FriendBuyScript'
import { GtmScript } from '../../../app/_components/chrome/scripts/GtmScript'
import { HeapScript } from '../../../app/_components/chrome/scripts/HeapScript'
import { NostoScript } from '../../../app/_components/chrome/scripts/NostoScript'
import { OneTrustScript } from '../../../app/_components/chrome/scripts/OneTrustScript'
import { AccentColorContainer } from '../../../containers/AccentColorContainer'
import { figsFont } from '../../../lib/font'

/**
 * > `next/script` must not be placed in either a `next/head` component or in `pages/_document.js`.
 * - https://nextjs.org/docs/basic-features/script
 *
 * We want one `meta[name="viewport"]` with `initial-scale=1`. It can't be set in MagnoliaDocument.
 * For `{ Head } from 'next/head` (but not `{ Head } from 'next/document'`, as in MagnoliaDocument)
 * `defaultHead` in https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/head.tsx
 * will push `<meta name="viewport" content="width=device-width" />` and `<meta charSet="utf-8" />`
 * unless you define them. However, `{ Head } from 'next/document'` does not inject default values.
 * Setting `meta[name="viewport"]` in MagnoliaDocument results in a duplicate tag with a bad value.
 *
 * Tested in `magnolia/cypress/e2e/smoke-tests/home.ts`.
 */
export const ContextualHead = () => {
  const { accentColor } = AccentColorContainer.useContainer()
  const { promoBar } = useFixturesContext()

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' key='viewport' />
        <meta
          name='theme-color'
          content={accentColor ?? promoBar?.backgroundColor ?? '#282828'}
          key='theme-color'
        />
        <style
          type='text/css'
          dangerouslySetInnerHTML={{ __html: `@charset 'utf-8'; ${figsFont}` }}
          key='figs-font-faces'
        />
      </Head>

      <DataDogRumScript />

      <OneTrustScript />
      <HeapScript />
      <NostoScript />
      <FriendBuyScript />
      <AttentiveScript />
      <GtmScript />
    </>
  )
}
