import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { useLocalization } from '@syconium/magnolia/src/app/_providers/LocalizationProvider.client'
import { NavListItem } from '@syconium/magnolia/src/types/graphql'

import { useAuthentication } from '../../app/_providers/AuthenticationProvider.client'
import { useTranslation } from '../../app/_providers/TranslationProvider.client'

/**
 * Logged Out:
 * - Log In
 * - Create Account
 *
 * Logged In:
 * - Profile
 * - Orders
 * - Addresses
 * - Preferences
 * - Log Out
 */
export const useAccountMenuItems = () => {
  const {
    magnolia: { account: strings },
  } = useTranslation()
  const { isLoggedIn, logOut: logOutFunction } = useAuthentication()
  const { isUSRegion } = useLocalization()
  const router = useRouter()

  const accountMenuItems: NavListItem[] = useMemo(() => {
    const createAccount = item({
      href: '/account/register',
      text: strings.createAccount,
    })
    const logIn = item({
      href: '/account/login',
      text: strings.logIn,
    })
    const logOut = item({
      hasDivider: true,
      onClickish: () => logOutFunction(),
      text: strings.logOut,
    })
    const profile = item({
      onClickish: () => router.push('/account'),
      text: strings.navigation.profile,
    })
    const orders = item({
      onClickish: () => router.push('/account/orders'),
      text: strings.navigation.orders,
    })
    const addresses = item({
      onClickish: () => router.push('/account/addresses'),
      text: strings.navigation.addresses,
    })

    return isLoggedIn
      ? [profile, orders, ...(isUSRegion ? [addresses] : []), logOut]
      : [logIn, createAccount]
  }, [
    isLoggedIn,
    isUSRegion,
    logOutFunction,
    router,
    strings.createAccount,
    strings.logIn,
    strings.logOut,
    strings.navigation.addresses,
    strings.navigation.orders,
    strings.navigation.profile,
  ])

  return {
    accountMenuItems,
  }
}

function item(input: Partial<NavListItem> & { text: string }): NavListItem {
  return {
    __typename: 'NavListItem',
    badge: null,
    badgeColor: null,
    badgeTextColor: null,
    href: null,
    icon: null,
    isBold: null,
    isHeading: null,
    textColor: null,
    htmlId: null,
    htmlClass: null,
    isBtn: null,
    alternateModelVariant: null,
    id: input.text || '',
    ...input,
  }
}
